import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
	<Layout title="Page Not Found">
		<div className="article">
			<h1>404 Not Found</h1>
		</div>
	</Layout>
)

export default NotFoundPage
